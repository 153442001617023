import React, { ReactElement } from "react";
import SideImage from "../sideImage";

export default function WhyUs(): ReactElement {
    return (
        <section className="max-w-7xl mx-auto ">
            <SideImage
                title={"Why CarAccidentAuthority.com?"}
                content={[
                    "● You don't have to pay us anything if we don't win.",
                    "● We won't stop fighting for you.",
                    "● Our lawyers make it easy to understand car accident law.",
                    "● We'll get you the maximum, fair compensation you deserve.",
                    "● We represent all kinds of car accident cases.",
                ]}
                image={"/assets/images/why_us.svg"}
                imageClassName={
                    "md:w-[580px] md:h-[380px] w-[300px] h-[200px] mt-3"
                }
                flip
            />
        </section>
    );
}
