import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import CarAccidentAuthorityWrapper from "@components/layouts/carAccidentAuthorityWrapper";
import ServiceForm from "@components/caraccidentauthority/serviceform";
import WhatToGain from "@components/caraccidentauthority/whatToGain";
import AccidentLawyer from "@components/caraccidentauthority/accidentLawyer";
import SuitTime from "@components/caraccidentauthority/suitTime";
import WhyUs from "@components/caraccidentauthority/whyUs";
import Settlement from "@components/caraccidentauthority/settlement";
import { useDomainContext } from "@hooks/useDomainContext";
import Disclaimer from "@components/shared/legal/Disclaimer";

const About = dynamic(() => import("@components/caraccidentauthority/about"));
const BottomSection = dynamic(
    () => import("@components/caraccidentauthority/bottomSection"),
);
const HowThisWork = dynamic(
    () => import("@components/caraccidentauthority/howThisWork"),
);
const OffersList = dynamic(() => import("@components/shared/offersList"));
export default function CarAccidentAuthorityHome({
    locale,
}: {
    locale: string;
}): ReactElement {
    const { domain } = useDomainContext();
    return (
        <CarAccidentAuthorityWrapper>
            <ServiceForm />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            {locale !== "es" ? (
                <>
                    <HowThisWork />
                    <BottomSection />
                    <AccidentLawyer />
                    <Settlement />
                    <SuitTime />
                    <WhatToGain />
                    <About />
                    <WhyUs />
                    <Disclaimer />
                </>
            ) : (
                <></>
            )}
        </CarAccidentAuthorityWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
